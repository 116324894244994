export const adminSidebars = [
	{
		name: 'Тесты',
		icon: 'fas fa-user',
		route: '/admin-page/tests'
	},
	{
		name: 'Пользователи',
		icon: 'fas fa-address-card',
		route: '/admin-page/users'
	},
	{
		name: 'Посты',
		icon: 'fas fa-chart-bar',
		route: '/admin-page/posts'
	},
	{
		name: 'Отзывы',
		icon: 'fas fa-comments',
		route: '/admin-page/reviews'
	},
	{
		name: 'Главная',
		icon: 'fas fa-syringe',
		route: '/admin-page/diseases'
	},
	{
		name: 'Элементари Парт',
		icon: 'fas fa-chart-line',
		route: '/admin-page/elementary'
	},
	{
		name: 'Бегиннер парт',
		icon: 'fas fa-chart-bar',
		route: '/admin-page/beginner'
	},
	{
		name: 'Пре-Интермедиет Парт',
		icon: 'fas fa-user',
		route: '/admin-page/pre-intermediate'
	},
];
