<template>
	<div class="admin-manage">
		<Header></Header>
		<Sidebar :sidebars="adminSidebars"></Sidebar>
		<div class="admin-manage__container">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import Header from "@/views/navigation/Header";
import Sidebar from "@/views/navigation/Sidebar";
import { adminSidebars } from "@/utils/sidebars";

export default {
name: "AdminManage",
	components: {Sidebar, Header},
	data () {
		return {
			adminSidebars
		}
	},
	computed: {
		loggedIn() {
			return this.$store.state.account.user;
		},
	},
	/*mounted() {
		if (this.userProfile.roles.some(i => i.code === 'ROLE_SUPER_MONITOR'))
			this.adminSidebars = this.adminSidebars.filter(i =>
				!i.route.includes('/uik') && !i.route.includes('/tik') &&
        !i.route.includes('/super-monitor') && !i.route.includes('/monitor') &&
        !i.route.includes('/coordinator') && !i.route.includes('/location')
			)
	}*/
}
</script>

<style lang="scss" scoped>
	.admin-manage {
		min-height: 100vh;
		background: #9da5ad;
		padding-top: 82px;
		&__container {
			margin-left: 230px;
			padding: 5px;

		}
	}
</style>
